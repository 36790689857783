<template>
  <b-card>
    <div class="my-2">
      <div class="row justify-content-between">
        <div class="col-md-4 col-12">
          </div>
          <div class="d-flex align-items-center justify-content-end mb-1 mb-md-0 col-md-4 col-12">
            <b-button
            variant="primary"
            data-action-type="new"
            @click="
              (v) => {
                this.$router.push({ name: 'custodySettlement-new' });
              }
            "
          >
            {{ $t('new') }}
          </b-button>
            <vue-excel-xlsx
              :data="itemsArray"
              :columns="tableColumns"
              :filename="this.$route.name"
              :sheetname="'xlsxSheet'"
              class="btn btn-relief-success ml-1 p-0 ptn-sm"
              >
              <img src="@/assets/images/icons/xls.jpg" alt="" style="height: 39px;width: auto;" />
            </vue-excel-xlsx>
             <b-button
              variant="relief-danger"
              class="ml-1 p-0 ptn-sm"
              @click="printDocument"
            >
              <img src="@/assets/images/icons/pdf.jpg" alt="" style="height: 39px;width: auto;" />
            </b-button>
          </div>
        </div>
    </div>
    <g-table
      ref="transaction-table"
      :items="items"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      foot-clone
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: false }"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :searchInput="{ visiable: true }"
    >
      <template #actions="{ item }" v-if="hideActions === false">
        <div class="text-nowrap">
            <feather-icon
            :id="`invoice-row-${item.id}-prev-icon`"
            icon="EyeIcon"
            data-action-type="preview"
            class="mx-1 clickable "
            :hidden="true"
            @click="edit(item)"
          />
          <b-tooltip
            :title="$t('preview')"
            placement="bottom"
            :target="`invoice-row-${item.id}-prev-icon`"
          />
          <b-button
            data-action-type="edit"
            v-b-tooltip.hover.top="$t('edit')"
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click=" () => { edit(item) }"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
            />
          </b-button>
          <b-button
            data-action-type="delete"
            v-b-tooltip.hover.top="$t('delete')"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="(v) => { remove(item) }"
          >
            <feather-icon
              icon="TrashIcon"
              stroke="red"
              class="danger"
              :id="`invoice-row-${item.id}-delete-icon`"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import GTable from '@/pages/Shared/Table.vue';

export default {
  components: {
    GTable,
  },
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      perPage: 25,
      isTableBusy: false,
      totalRows: 0,
      items: [],
      employees: [],
      pathName: this.$route.name,
      totalVals: 0,
      itemsArray: [],
      hideActions: false
    };
  },
  computed: {
    tableColumns() {
      return [
        {
        key: 'code',
        field: 'code',
        label: this.$t('code'),
        sortable: false
        },
        {
          key: 'employeeArabicName',
          field: 'employeeArabicName',
          label: this.$t('employee'),
          sortable: false,
        },
        {
        key: 'transactionDate',
        field: 'transactionDate',
        label: this.$t('date'),
        sortable: false
        },
        {
          key: 'notes',
          field: 'notes',
          label: this.$t('notes'),
          sortable: false,
        },
        { key: 'actions' },
      ];
    },
  },
  mounted() {
    this.getEmployees();
  },
  methods: {
    hide() {
      this.hideActions = true
    },
    pdfExport(name) {
      html2canvas(document.getElementById('mainTable')).then(function(canvas){
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
          doc.save(`${name}.pdf`);
      });
    },
    printDocument() {
      this.hide()
        setTimeout(() => {this.pdfExport(this.$route.name)
        this.hideActions = false
        }, 500);

    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    refreshItems() {
      this.$refs['transaction-table'].refreshTable();
    },
    getEmployees() {
      this.get({ url: 'employees' }).then((data) => {
        this.employees = data;
      }).finally(() => {
        this.getItems();
      })
    },
    getItems() {
        this.get({ url: 'FinancialCustodySettlements' }).then((data) => {
          data.forEach((item) => {
            this.totalVals += item.value;
            item.transactionDate = this.getDate(item.transactionDate);
            const employeeName = this.employees.find(
            (val) => val.id === item.employeeId
            ) || {
              arabicName: '',
              englishName: '',
            };
            item.employeeArabicName = employeeName.arabicName;
          });
        this.items = data;
      });
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
            this.delete({
              url: 'FinancialCustodySettlements',
              id: item.id,
            }).then(() => {
              this.doneAlert({ text: this.$t('deletedSuccessfully') });
              this.refreshItems()
            });
        }
      );
    },
    edit(item) {
        this.$router.push({
          name: 'custodySettlement-edit',
          params: { id: item.id },
        });
    }
  },
};
</script>

<style></style>
